.login {
  &__submit {
    &--hidden {
      position: absolute;
      top: -9999px;
      z-index: -1;
    }
  }

  &__btn-next:not(.login__btn-spacer) {
    margin-top: var(--spacing);
  }

  &__btn-spacer {
    margin-bottom: var(--spacing);
    margin-top: 102px;

    @media (--screen-md) {
      margin-top: var(--spacing-l);
    }
  }

  #div_id_password {
    margin-bottom: 2.25rem;

    @media (min-width: 991px) {
      margin-bottom: 4.95rem;
    }
  }

  #id_token-otp_token.numberinput.form-control {
    max-width: 330px;
  }

  #login-back-button {
    @media (max-width: 340px) {
      margin-bottom: 0.2rem;
    }
  }

  &__link {
    color: var(--bn-orange);
    text-decoration: underline;
  }
}

.form-container {
  padding-top: 1rem;
}

.asteriskField {
  display: none;
}

.login-flex-parent {
  display: flex;
  padding-top: 3rem;

  @media (--screen-md-max) {
    flex-direction: column;
    padding-top: 0;
  }
}

.login-container {
  padding: 0.5rem;
  width: 100%;

  h1 {
    margin-bottom: 1.5rem;
  }
}

.signup-container {
  width: 100%;

  h1 {
    margin-bottom: 1.5rem;
  }

  @media (min-width: 991px) {
    border-left: 1px solid var(--border-grey-2);
    padding: 0.5rem 0.5rem 0.5rem 3.5rem;
  }

  @media (--screen-md-max) {
    border-top: 1px solid var(--border-grey-2);
    padding-top: 1.5rem;
  }
}

.membership-benefits {
  display: flex;
  flex-direction: column;
  width: 90%;

  &__benefit {
    display: flex;
    margin-bottom: 0.75rem;

    &:first-of-type {
      margin-top: 1rem;
    }

    &:last-of-type {
      margin-bottom: 2.6rem;
    }

    &__description {
      align-items: center;
      display: flex;
      flex-grow: 8;
      padding-left: 1rem;
    }

    &__svg-bullet {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      flex-grow: 1;
      height: 2.5rem;
      margin: 0.5rem 0;
      width: 2.5rem;
    }
  }
}

.award-badge {
  background-image: url("/static/img/icons/award-badge.svg");
}

.group-communication {
  background-image: url("/static/img/icons/group-communication.svg");
}

.briefcase {
  background-image: url("/static/img/icons/briefcase.svg");
}

.verification-needed {
  margin: auto;
  max-width: 58%;
  padding: 4rem 0;

  &__message {
    background-color: white;
    border: var(--box-border);
    border-radius: var(--box-border-radius-s);
    box-shadow: var(--box-shadow);
    padding: var(--spacing-xl);
    text-align: center;

    &__email-svg {
      background-image: url("/static/img/icons/verify-account.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 8rem;
      margin: 1.5rem auto 1.8rem;
      width: 8rem;

      @media (--screen-lg) {
        margin: 0.8rem auto 1.5rem;
      }
    }

    &__resend-email-link,
    &__title {
      margin-bottom: 1.5rem;
    }

    &__contact-us > a {
      color: var(--bn-orange);
      margin-bottom: 1.5rem;
      text-decoration: underline;
    }

    @media (--screen-lg) {
      padding: 1.8rem;
    }
  }

  @media (--screen-md) {
    max-width: 90%;
    padding: 1rem 0;
  }
}
